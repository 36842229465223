const MENU_MOBILE_SCROLL = document.querySelector(".floating-menu__mobile");
const FLOATING_MENU = document.querySelector(".floating-menu");

module.exports = () => {
    if(!MENU_MOBILE_SCROLL) return;

    MENU_MOBILE_SCROLL.addEventListener("click", (e) => {
        const self = e.target !=  MENU_MOBILE_SCROLL ? MENU_MOBILE_SCROLL : e.target;
        self.classList.toggle("is-active");

        FLOATING_MENU.classList.toggle("is-active");
    });
};
