import burger from "./modules/burger";
import FixedElementClearance from "./modules/FixedElementClearance";
import LazyLoad from "vanilla-lazyload";
import MenuToggle from "./modules/MenuToggle";
import SmoothScroll from "smooth-scroll/dist/smooth-scroll";
// import { init } from "aos";

// Less used modules
//
// import accordion from "./modules/accordion";
// import DragonModal from "./modules/DragonModal";
// import DragonTabs from "./modules/DragonTabs";
import modal from "./modules/modal";
import slides from "./modules/slides";
import header from "./modules/header";

// CUSTOM MODULES
import scrollMain from "./modules/scroll-main";

// NEW MODULES
import menuMobileScroll from "./modules/menu-mobile-scroll";

export default () => {
    burger();
    menuMobileScroll();
    // init();

    new LazyLoad({ elements_selector: ".lazy" });

    new SmoothScroll('a[href*="#"]', {
        header: ".main-header-bundle",
        speed: 1500,
        speedAsDuration: true,
        easing: "easeOutQuint",
    });

    new FixedElementClearance({
        element: ".js-get-main-header-height",
        CSSVariableName: "main-header-height",
    });

    new MenuToggle({
        menuToggle: [".js-main-menu-mobile-toggle"],
        menuElement: ".main-menu-mobile",
        menuClose: [".js-close-menu-mobile"],
        activeBodyClass: "mobile-menu-is-open",
        breakpointToHide: 1200,
    });

    // Less used modules
    //
    // accordion();
    // new DragonModal();
    // new DragonTabs();
    new modal();
    
    slides();
    header();
};
