const FLOATING_MENU = document.querySelector(".floating-menu");

const toggleClassScroll = changeClass => {
    if (changeClass) {
        FLOATING_MENU.classList.add("scroll-changed");
    } else {
        FLOATING_MENU.classList.remove("scroll-changed");
    }
};

const listenerScrollToChangeMenu = currentScroll => {
    if (window.innerWidth >= 1200) {
        if (currentScroll < 359) {
            toggleClassScroll(false);
        }

        if (currentScroll > 359) {
            toggleClassScroll(true);
        }

        if (currentScroll > 989) toggleClassScroll(false);
        if (currentScroll > 3025) toggleClassScroll(true);
        if (currentScroll > 5833 && window.innerWidth < 1800) toggleClassScroll(false);
        if (currentScroll > 5000 && window.innerWidth > 1800) toggleClassScroll(false);
    }
};

module.exports = window.addEventListener("scroll", e => {
    listenerScrollToChangeMenu(window.scrollY);
});
